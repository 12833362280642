import CTA from 'components/UI/Cta';
import Image from 'components/UI/Image';
import RichText from 'components/UI/Richtext';
import SiteDataContext from 'context/SiteDataContext';
import { ReactElement, useContext } from 'react';
import { IComponentHeroPrimaryCircleFields } from 'types/contentful';

export default function HeroPrimaryCircle({
  heading,
  copy,
  image,
  cta,
}: IComponentHeroPrimaryCircleFields): ReactElement {
  const { colors } = useContext(SiteDataContext);
  const Circle = (): ReactElement => {
    return (
      <svg
        width="830"
        height="599"
        viewBox="0 0 830 599"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="hidden lg:block"
      >
        <defs>
          <clipPath id="cut-off-bottom">
            <rect x="0" y="0" width="830" height="535" />
          </clipPath>
        </defs>

        <ellipse
          cx="323.5"
          cy="94.1827"
          rx="506.5"
          ry="504.817"
          clipPath="url(#cut-off-bottom)"
          fill={`rgb(${colors?.highlightColor})`}
        />
      </svg>
    );
  };

  const MobileCircle = (): ReactElement => {
    return (
      <svg
        width="318"
        height="325"
        viewBox="0 0 318 325"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className=""
      >
        <ellipse cx="97" cy="134" rx="221" ry="220" fill={`rgb(${colors?.highlightColor})`} />
      </svg>
    );
  };

  return (
    <div className="relative overflow-hidden h-hero-mobile lg:h-auto">
      <Circle />
      <div className="w-4/5 lg:hidden relative flex h-full">
        <div
          className="block h-full flex-1"
          style={{ backgroundColor: `rgb(${colors?.highlightColor})` }}
        ></div>
        <MobileCircle />
      </div>
      <div className="absolute right-0 bg-primary w-full h-full top-0 lg:-mt-16 -z-1"></div>
      <div className="absolute flex inset-0">
        <div className="w-full lg:w-11/20">
          <div className="mx-4 md:ml-10 lg:ml-16 xl:ml-32 mt-6 md:mt-10 lg:mt-32 xl:mt-40 lg:pr-6 xl:pr-16">
            <div className="w-3/4 lg:w-full text-5xl">
              <RichText
                document={heading}
                highlight="white"
                classNames="text-4xl lg:text-5xl xl:text-6xl font-medium leading-none font-heading"
              />
              <RichText document={copy} classNames="text-base lg:text-xl mt-6" />
            </div>
            {cta && <CTA {...cta.fields} classNames="mt-6" />}
          </div>
        </div>
        <div className="hidden lg:block mt-16" style={{ width: 672 }}>
          <Image image={image} query="w=672&h=540" classNames="h-full w-full object-cover" />
        </div>
      </div>
    </div>
  );
}
